import { CircularProgress, Grid, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  approveAuthor, deleteAuthor, getNamespacePath, rejectAuthor, selectContext, setAuthorsContext,
} from '../redux/features/curatedSlice';
import { selectUserProfile } from '../redux/features/usersSlice';
import StyledBackdrop from '../components/StyledBackdrop';
import AuthorCardGrid from '../components/AuthorCardGrid';
import Login from '../components/Login';

function contextUpdate(context, searchParams) {
  const params = new URLSearchParams(searchParams);
  const page = +params.get('page');
  const filter = params.get('filter');

  const contextParams = context
    ? {
      pageNumber: context.pageNumber,
      ...(context.filter && { filter: context.filter }),
    } : {};

  const update = {
    pageNumber: page || 1,
    ...(filter && { filter }),
  };

  if (JSON.stringify(contextParams) !== JSON.stringify(update)) {
    return update;
  }
}

function searchParamsUpdate(context, searchParams) {
  const update = context
    ? {
      ...(context.pageNumber && context.pageNumber !== 1 && { page: context.pageNumber }),
      ...(context.filter && { filter: context.filter }),
    } : {};

  const params = searchParams.toString();
  const result = new URLSearchParams(update).toString();
  if (params !== result) {
    return result;
  }

  return null;
}

function addPages(searchParams, increment) {
  const page = searchParams.get('page');
  const filter = searchParams.get('filter');

  const newPage = (page ? +page : 1) + increment;
  const params = {
    ...(newPage > 1 && { page: newPage }),
    ...(filter && { filter }),
  };

  return new URLSearchParams(params).toString();
}

function AuthorsView(props) {
  const type = 'authors';
  const { namespace } = props;
  const isPublicView = namespace === 'public';
  const namespacePath = getNamespacePath(namespace);
  const userProfile = useSelector(selectUserProfile);

  const [searchParams] = useSearchParams();
  const status = useSelector((state) => state.curated.contextStatus);
  const currContext = useSelector(selectContext);
  const context = (currContext
    && currContext.namespace === namespace
    && currContext.type === type) ? currContext : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const page = +searchParams.get('page');
  const filter = searchParams.get('filter');

  useEffect(() => {
    if ((!isPublicView && !userProfile) || status === 'loading') {
      return;
    }
    const update = contextUpdate(context, searchParams);
    if (update) {
      const newContext = {
        ...(namespace && { namespace }),
        type,
        ...update,
      };
      dispatch(setAuthorsContext(newContext));
    }
  }, [page, filter, namespace]);

  useEffect(() => {
    if (!userProfile) return;
    const update = contextUpdate(null, searchParams);
    if (update) {
      const newContext = {
        ...(namespace && { namespace }),
        type,
        ...update,
      };
      dispatch(setAuthorsContext(newContext));
    }
  }, [userProfile]);

  useEffect(() => {
    if (status === 'loading') return;
    const update = searchParamsUpdate(context, searchParams);
    if (update) { navigate({ search: update }); }
  }, [status]);

  const onAuthorOpen = (author) => { window.open(author.sourceUrl, '_blank'); };
  const onAuthorRead = (author) => { navigate(`/${namespacePath}/author/${author.id}`); };
  const onAuthorDelete = (author) => {
    dispatch(deleteAuthor({ author, namespace, refresh: true }));
  };
  const onAuthorReject = (author) => { dispatch(rejectAuthor(author)); };
  const onAuthorShare = (author) => { dispatch(shareAuthor({ author })); };

  const onAuthorApprove = (author) => {
    dispatch(approveAuthor(author));
  };

  const onAuthorEdit = (author) => {
    navigate(`/edit/author/${author.id}`);
  };

  const onAuthorMerge = (targetItemId, sourceItemIds) => {
    dispatch(mergeAuthors({ targetItemId, sourceItemIds }));
  };

  const onAuthorFavorite = (author) => {
    const newAuthor = { id: author.id, isFavorite: !author.isFavorite };
    dispatch(updateAuthor({ author: newAuthor, namespace }));
  };

  const handleNextPage = () => {
    navigate({ search: addPages(searchParams, 1) });
  };

  const handlePreviousPage = () => {
    navigate({ search: addPages(searchParams, -1) });
  };

  return (
    <>
      <Toolbar />
      { !isPublicView && !userProfile && <Login />}
      { (isPublicView || userProfile) && (
        <StyledBackdrop open={!context}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}
      { (isPublicView || userProfile) && context && (
        <Grid container>
          <Grid xs={1} item />
          <Grid xs={10} item>
            <AuthorCardGrid
              authorActions={{
                ...(namespace === 'pending' && { onAuthorApprove }),
                onAuthorOpen,
                onAuthorRead,
                onAuthorEdit,
                onAuthorFavorite,
                onAuthorDelete: namespace === 'pending' ? onAuthorReject : onAuthorDelete,
                onAuthorShare,
                onAuthorMerge,
              }}
              currentPage={context}
              isReadOnly={false}
              listActions={{
                handlePreviousPage,
                handleNextPage,
              }}
              pageNumber={page}
            />
          </Grid>
          <Grid xs={1} item />
        </Grid>
      )}
    </>
  );
}

export default AuthorsView;
