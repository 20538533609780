import { CircularProgress, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createAuthor, deleteAuthor, selectContext, setContext, updateAuthor,
} from '../redux/features/curatedSlice';
import { setSnackbar } from '../redux/features/snackbarSlice';
import StyledBackdrop from '../components/StyledBackdrop';
import AuthorForm from '../components/AuthorForm';

export default function AuthorUpdateView(props) {
  const { namespace } = props;
  const { authorId } = useParams();

  const authorCreateOrUpdate = authorId ? updateAuthor : createAuthor;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useSelector(selectContext);

  useEffect(() => {
    if (authorId) {
      dispatch(setContext({
        type: 'author',
        author: { id: authorId },
      }));
    }
  }, [authorId]);

  const author = authorId ? context && context.author : {};
  // post-create state -> redirect
  if (context && context.author && !authorId) {
    navigate(`/edit/author/${context.author.id}`, { replace: true });
  }

  const onSaveAuthor = (newAuthor) => {
    dispatch(authorCreateOrUpdate({ author: newAuthor, namespace }))
      .unwrap()
      .then(() => {
        dispatch(setSnackbar({
          snackbarOpen: true,
          snackbarMessage: 'Author saved.',
        }));
      })
      .catch(() => {
        dispatch(setSnackbar({
          snackbarOpen: true,
          snackbarType: 'error',
          snackbarMessage: 'Failed to save the author.',
        }));
      });
  };

  const onDeleteAuthor = (authorDelete) => {
    dispatch(deleteAuthor({ author: authorDelete }));
    navigate(-1);
  };

  const onCancel = () => navigate(-1);

  const [backdropOpen, setOpen] = React.useState(!author);
  const newOpen = !author;
  if (newOpen !== backdropOpen) {
    setOpen(newOpen);
  }

  return (
    <>
      <Toolbar />
      <StyledBackdrop open={!author}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      { author && (
        <AuthorForm
          author={author}
          onCancel={onCancel}
          onDeleteAuthor={onDeleteAuthor}
          onSaveAuthor={onSaveAuthor}
        />
      )}
    </>
  );
}
