import { useSelector } from 'react-redux';
import {
  Avatar, ButtonBase, Card, CardActions, CardContent, CardHeader, CardMedia,
  Chip, Collapse, IconButton, Link, Menu, MenuItem, Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import CodeIcon from '@mui/icons-material/Code';
import CommentIcon from '@mui/icons-material/Comment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import LikeIcon from '@mui/icons-material/ThumbUp';
import HideIcon from '@mui/icons-material/VisibilityOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import amazonLogo from '../resources/amazon.svg';
import hnLogo from '../resources/hackernews.png';
import githubLogo from '../resources/github.svg';
import goodreadsLogo from '../resources/goodreads.svg';
import lobstersLogo from '../resources/lobsters.png';
import redditLogo from '../resources/reddit.png';
import slashdotLogo from '../resources/slashdot.png';
import twitterLogo from '../resources/twitter.png';
import wikipediaLogo from '../resources/wikipedia.png';
import youtubeLogo from '../resources/youtube.svg';
import * as ItemUtils from '../common/ItemTools';
import { buildMediaTypeNameMap } from '../common/MediaTypes';
import SelectListDialog from './SelectListDialog';
import { selectAuthors } from '../redux/features/curatedSlice';

const typeNameMap = buildMediaTypeNameMap();

const StyledCard = styled(Card)((props) => ({
  ...(props.item.timeReviewed && { border: 'solid', borderColor: 'green' }),
  '&:hover': {
    boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.5)',
  },
}));

const StyledCardHeader = styled(CardHeader)(({ item }) => ({
  '& .MuiCardHeader-title': {
    fontWeight: 'bold',
    ...(item && (item.authorIds ? { color: 'green' } : { color: 'red' })),
  },
  '& .MuiCardHeader-subheader': {
    textTransform: 'lowercase',
  },
}));

const StyledCardMedia = styled(CardMedia)({
  cursor: 'pointer',
  height: '250px',
  paddingTop: '56.25%', // 16:9
  backgroundSize: 'contain',
});

const Title = styled(Typography)({
  fontFamily: 'Merriweather',
  marginTop: '5px',
  marginBottom: '10px',
  '&:hover': { cursor: 'pointer' },
});

const Excerpt = styled(Typography)({
  fontFamily: 'Merriweather',
  whiteSpace: 'pre-line',
  margin: '3px',
  '&:hover': { cursor: 'pointer' },
});

const StyledCollapse = styled(Collapse)({
  marginLeft: '15px',
});

const TagsBox = styled(Box)({
  flexGrow: 1,
});

const TagChip = styled(Chip)({
  margin: '2px',
});

const Discussion = styled(Avatar)({
  width: 22,
  height: 22,
  marginLeft: '3px',
});

const ActionsBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const MetricsBox = styled(Box)({
  display: 'flex',
});

const MetricBox = styled(Box)({
  borderRadius: '5px',
  fontWeight: 'bold',
  fontSize: 'smaller',
  border: '1px solid',
  margin: '3px',
  padding: '2px',
});

const ShareIconButton = styled(IconButton)(
  ({ itemVisibility }) => {
    if (itemVisibility === 'public') return { backgroundColor: 'green' };
    if (itemVisibility === 'hidden') return { backgroundColor: 'black' };
  },
);

const StyledShareIcon = styled(ShareIcon)(
  ({ itemVisibility }) => {
    if (itemVisibility === 'public') return { color: 'gold' };
    if (itemVisibility === 'hidden') return { color: 'red' };
  },
);

const StyledDoneIcon = styled(DoneIcon)(({ checked }) => checked && { color: 'green' });

const StyledHideIcon = styled(HideIcon)(({ hidden }) => hidden && { color: 'red' });

const StyledLikeIcon = styled(LikeIcon)(({ checked }) => checked && { color: 'red' });

const StyledBookmarkIcon = styled(BookmarkIcon)(({ checked }) => checked && { color: 'green' });

const Score = styled(Avatar)((props) => {
  const { item, theme } = props;
  let style = {
    width: theme.spacing(4),
    height: theme.spacing(4),
  };
  const score = ItemUtils.calculateCompletenessScore(item);
  if (score === 100) {
    style = {
      ...style,
      backgroundColor: 'green',
      color: 'gold',
      fontSize: 'medium',
    };
  } else if (score > 60) {
    style.backgroundColor = 'orange';
  } else {
    style.backgroundColor = 'red';
  }
  return style;
});

const Expand = styled(ExpandMoreIcon)(({ expanded, theme }) => ({
  transform: (expanded ? 'rotate(180deg)' : 'rotate(0deg)'),
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function openCustomUrl(url) {
  window.open(url, '_blank');
}

function getLogoSource(domain) {
  if (domain.includes('ycombinator')) {
    return hnLogo;
  } if (domain.includes('lobste.rs')) {
    return lobstersLogo;
  } if (domain.includes('twitter.com')) {
    return twitterLogo;
  } if (domain.includes('slashdot.org')) {
    return slashdotLogo;
  } if (domain.includes('reddit.com')) {
    return redditLogo;
  } if (domain.includes('github.com')) {
    return githubLogo;
  } if (domain.includes('wikipedia.org')) {
    return wikipediaLogo;
  } if (domain.includes('goodreads.com')) {
    return goodreadsLogo;
  } if (domain.includes('youtube.com')) {
    return youtubeLogo;
  } if (domain.includes('amazon.com')) {
    return amazonLogo;
  }

  return null;
}

function getItemMetrics(item) {
  const timePublishedMoment = item.timePublished
    && `${ItemUtils.convertUnixTimeToMoment(item.timePublished)}`;
  const timeCreatedMoment = item.timeCreated
    && `${ItemUtils.convertUnixTimeToMoment(item.timeCreated)}`;
  const timeLeftMoment = item.timeNeeded
    && `${ItemUtils.humanizeIntervalInMinutes(item.timeNeeded)}`;
  const type = item.type && typeNameMap[item.type];
  const label = type && type.label;
  const color = (type && type.color) || 'blue';

  const result = {
    ...(type && { type }),
    ...((timePublishedMoment || timeCreatedMoment)
      && { date: timePublishedMoment || timeCreatedMoment }),
    ...(timeLeftMoment && { length: timeLeftMoment }),
    ...(label && { label }),
    ...(color && { color }),
  };

  return result;
}

export default function ItemCard(props) {
  const {
    isPreview,
    onAuthorView,
    onItemApprove,
    onItemBookmark,
    onItemClone,
    onItemEdit,
    onItemHide,
    onItemDelete,
    onItemFavorite,
    onItemAddToList,
    onItemRead,
    onItemShare,
    onTagOpen,
    onItemMerge,
  } = props;
  let { item } = props;

  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleListDialogClose = (listInfo) => {
    setDialogOpen(false);
    onItemAddToList(item, listInfo);
  };
  const [, drag] = useDrag(() => ({
    type: 'box',
    item: { name: item.id },
    end: (dragItem, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dragItem && dropResult) {
        onItemMerge(dropResult.name, [dragItem.name]);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: 'box',
    drop: () => ({ name: item.id }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const menuOptions = [];
  let menuId = 0;
  if (onItemEdit) {
    menuOptions.push({ id: menuId += 1, label: 'Edit', action: onItemEdit });
  }
  if (onItemDelete) {
    menuOptions.push({ id: menuId += 1, label: 'Delete', action: onItemDelete });
  }
  if (onItemAddToList) {
    menuOptions.push({ id: menuId += 1, label: 'Add to list', action: () => setDialogOpen(true) });
  }
  if (onItemClone) {
    menuOptions.push({ id: menuId += 1, label: 'Make a copy', action: onItemClone });
  }

  const itemAuthors = useSelector((state) => selectAuthors(state, item.authorIds));
  if (itemAuthors) {
    item = { ...item, authors: itemAuthors };
  }

  const imageUrl = ItemUtils.getImageUrl(item);

  const menuOpen = Boolean(menuAnchor);
  const handleMoreActionsClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = (event, option, menuItem) => {
    if (option && option.action) option.action(menuItem);
    setMenuAnchor(null);
  };

  const metrics = getItemMetrics(item);
  const logoUrl = ItemUtils.getLogoUrl(item);
  const sourceLetter = ItemUtils.getSourceLetter(item);
  const { id: titleAuthorId, name: titleAuthorName } = ItemUtils.getAuthor(item);

  return (
    <StyledCard item={item} ref={drag} raised>
      <StyledCardHeader
        action={(
          <ActionsBox>
            {!isPreview
              && (
              <Score item={item}>
                {ItemUtils.calculateCompletenessScore(item)}
              </Score>
              )}
            {menuOptions.length > 0
              && (
              <IconButton onClick={handleMoreActionsClick}>
                <MoreVertIcon />
              </IconButton>
              )}
          </ActionsBox>
        )}
        avatar={
          (logoUrl || sourceLetter)
          && (
          <Avatar src={logoUrl}>
            {sourceLetter}
          </Avatar>
          )
        }
        item={item}
        subheader={(
          <MetricsBox>
            { metrics.label
              ? <MetricBox color={metrics.color}>{metrics.label}</MetricBox>
              : <MetricBox backgroundColor="red">unknown</MetricBox> }
            { metrics.length && <MetricBox color="black">{metrics.length}</MetricBox> }
            { metrics.date && <MetricBox color="purple">{metrics.date}</MetricBox> }
          </MetricsBox>
        )}
        title={
          titleAuthorId
            ? (
              <Link
                color="inherit"
                sx={{ cursor: 'pointer' }}
                underline="none"
                onClick={() => onAuthorView(titleAuthorId)}
              >
                {titleAuthorName}
              </Link>
            )
            : titleAuthorName
        }
      />
      <Link
        color="inherit"
        underline="none"
        onClick={() => onItemRead(item)}
      >
        <StyledCardMedia component="div" image={imageUrl} ref={drop} />
        <CardContent>
          <Title variant="h5">
            {item.title}
          </Title>
          {item.excerpt && (
            <Excerpt component="span" variant="body2">
              {item.excerpt}
            </Excerpt>
          )}
        </CardContent>
      </Link>
      <StyledCollapse in={expanded} timeout="auto" unmountOnExit>
        <div onClick={() => { navigator.clipboard.writeText(JSON.stringify(item)); }}>
          <b>ID: </b>
          {item.id}
          {itemAuthors
          && (
            <div>
              <b>Authors: </b>
              {itemAuthors.map((author) => (
                <TagChip
                  key={author.id}
                  label={ItemUtils.getAuthorName(author)}
                  variant="default"
                  onClick={() => onAuthorView(author.id)}
                />
              ))}
            </div>
          )}
          {item.relatedItems
          && (
            <div>
              <b>Related items: </b>
              {item.relatedItems.map((ri) => (
                <TagChip
                  key={ri.id}
                  label={ri.id}
                  variant="default"
                  onClick={() => onItemRead(ri)}
                />
              ))}
            </div>
          )}
        </div>
      </StyledCollapse>
      <CardActions disableSpacing>
        <div>
          {item.discussions && item.discussions.map((discussion) => {
            const domain = ItemUtils.getDomainFromUrl(discussion.url);
            const src = getLogoSource(domain);
            if (src) {
              return (
                <ButtonBase
                  key={discussion.url}
                  onClick={() => openCustomUrl(discussion.url)}
                >
                  <Discussion
                    src={src}
                    variant="rounded"
                  />
                </ButtonBase>
              );
            }
            return (
              <ButtonBase
                key={discussion.url}
                onClick={() => openCustomUrl(discussion.url)}
              >
                <Discussion variant="rounded">
                  <CommentIcon />
                </Discussion>
              </ButtonBase>
            );
          })}
        </div>
        <TagsBox>
          {item.tags && item.tags.map((data) => {
            let icon;
            if (data.name === 'coding') {
              icon = <CodeIcon />;
            }
            return (
              <TagChip
                icon={icon}
                key={data.name}
                label={data.name}
                variant="default"
                onClick={onTagOpen ? () => onTagOpen(data.name) : onTagOpen}
              />
            );
          })}
        </TagsBox>
        { onItemBookmark
          && (
          <IconButton size="small" onClick={() => onItemBookmark({ id: item.id })}>
            <StyledBookmarkIcon checked={item.hasPrivateData} />
          </IconButton>
          )}
        { onItemShare
          && (
          <ShareIconButton
            itemVisibility={item.visibility}
            size="small"
            onClick={() => onItemShare(item)}
          >
            <StyledShareIcon itemVisibility={item.visibility} />
          </ShareIconButton>
          )}
        { onItemHide
          && (
          <IconButton size="small" onClick={() => onItemHide(item)}>
            <StyledHideIcon hidden={item.visibility === 'hidden' ? 1 : 0} />
          </IconButton>
          )}
        { onItemApprove
          && (
          <IconButton size="small" onClick={() => onItemApprove(item)}>
            <StyledDoneIcon checked={item.timeReviewed ? 1 : 0} />
          </IconButton>
          )}
        { onItemFavorite
          && (
          <IconButton size="small" onClick={() => onItemFavorite(item)}>
            <StyledLikeIcon checked={item.isFavorite ? 1 : 0} />
          </IconButton>
          )}
        { !onItemFavorite && item.isFavorite
          && <StyledLikeIcon checked={1} />}
        <Expand
          expanded={expanded ? 1 : 0}
          size="small"
          onClick={handleExpandClick}
        />
      </CardActions>
      <Menu
        anchorEl={menuAnchor}
        id="more-menu"
        open={menuOpen}
        keepMounted
        onClose={handleMenuClose}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={(event) => handleMenuClose(event, option, item)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <SelectListDialog open={dialogOpen} onClose={handleListDialogClose} />
    </StyledCard>
  );
}

ItemCard.propTypes = {
  isPreview: PropTypes.bool,
  onItemApprove: PropTypes.func,
  onItemEdit: PropTypes.func,
  onItemAddToList: PropTypes.func,
  onItemDelete: PropTypes.func,
  onItemFavorite: PropTypes.func,
  onItemRead: PropTypes.func,
  onItemShare: PropTypes.func,
  onTagOpen: PropTypes.func,
};

ItemCard.defaultProps = {
  isPreview: false,
  onItemApprove: null,
  onItemEdit: null,
  onItemAddToList: null,
  onItemDelete: null,
  onItemFavorite: null,
  onItemRead: null,
  onItemShare: null,
  onTagOpen: null,
};
