import { Box, styled } from '@mui/system';
import React from 'react';
import PropTypes from 'prop-types';
import PeopleIcon from '@mui/icons-material/People';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LikedIcon from '@mui/icons-material/ThumbUp';
import HistoryIcon from '@mui/icons-material/History';
import VideoIcon from '@mui/icons-material/VideoLibrary';
import PodcastIcon from '@mui/icons-material/Podcasts';
import BookIcon from '@mui/icons-material/MenuBook';
import PaperIcon from '@mui/icons-material/Article';
import ToolIcon from '@mui/icons-material/Build';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import {
  Drawer, List, ListItemText, ListItemIcon, Toolbar, ListSubheader, ListItemButton,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledDrawer = styled(Drawer)({
  width: '160px',
  flexShrink: 0,
});

const DrawerBox = styled(Box)({
  overflow: 'auto',
});

const StyledList = styled('ul')({
  backgroundColor: 'inherit',
  padding: 0,
});

const ListSection = styled('li')({
  backgroundColor: 'inherit',
});

const ListRoot = styled(List)(({ theme }) => ({
  width: '100%',
  maxWidth: 360,
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  overflow: 'auto',
}));

function ViewDrawer(props) {
  const { drawerOpen, setDrawerOpen } = props;
  const navigate = useNavigate();

  return (
    <StyledDrawer open={drawerOpen} variant="persistent">
      <Toolbar />
      <DrawerBox>
        <ListRoot subheader={<li />}>
          <ListSection key="learn">
            <StyledList>
              <ListSubheader>Learn</ListSubheader>
              <ListItemButton key="itemsLearn" onClick={() => { navigate('/learn/items'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="My Items" />
              </ListItemButton>
              <ListItemButton key="listsLearn" onClick={() => { navigate('/learn/lists'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <FormatListBulleted />
                </ListItemIcon>
                <ListItemText primary="My Lists" />
              </ListItemButton>
              <ListItemButton key="authorsLearn" onClick={() => { navigate('/learn/authors'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="My Authors" />
              </ListItemButton>
              <ListItemButton key="liked" onClick={() => { navigate('/learn/items?filter=fav%3Atrue'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <LikedIcon />
                </ListItemIcon>
                <ListItemText primary="Liked Items" />
              </ListItemButton>
              <ListItemButton key="history" onClick={() => { navigate('/learn/items?filter=treviewed%3A0'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary="History" />
              </ListItemButton>
              <Divider />
              <ListItemButton key="videos" onClick={() => { navigate('/learn/items?filter=type%3A3'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <VideoIcon />
                </ListItemIcon>
                <ListItemText primary="Videos" />
              </ListItemButton>
              <ListItemButton key="podcasts" onClick={() => { navigate('/learn/items?filter=type%3A13'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <PodcastIcon />
                </ListItemIcon>
                <ListItemText primary="Podcasts" />
              </ListItemButton>
              <ListItemButton key="books" onClick={() => { navigate('/learn/items?filter=type%3A2'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText primary="Books" />
              </ListItemButton>
              <ListItemButton key="papers" onClick={() => { navigate('/learn/items?filter=type%3A9'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <PaperIcon />
                </ListItemIcon>
                <ListItemText primary="Papers" />
              </ListItemButton>
              <ListItemButton key="tools" onClick={() => { navigate('/learn/items?filter=type%3A7'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <ToolIcon />
                </ListItemIcon>
                <ListItemText primary="Tools" />
              </ListItemButton>
              <Divider />
            </StyledList>
          </ListSection>
          <ListSection key="explore">
            <StyledList>
              <ListSubheader>Explore</ListSubheader>
              <ListItemButton key="itemsExplore" onClick={() => { navigate('/explore/items'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Explore Items" />
              </ListItemButton>
              <ListItemButton key="listsExplore" onClick={() => { navigate('/explore/lists'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <FormatListBulleted />
                </ListItemIcon>
                <ListItemText primary="Explore Lists" />
              </ListItemButton>
              <ListItemButton key="authorsExplore" onClick={() => { navigate('/explore/authors'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Explore Authors" />
              </ListItemButton>
              <Divider />
              <ListItemButton key="videos2" onClick={() => { navigate('/explore/items?filter=type%3A3'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <VideoIcon />
                </ListItemIcon>
                <ListItemText primary="Videos" />
              </ListItemButton>
              <ListItemButton key="podcasts2" onClick={() => { navigate('/explore/items?filter=type%3A13'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <PodcastIcon />
                </ListItemIcon>
                <ListItemText primary="Podcasts" />
              </ListItemButton>
              <ListItemButton key="books2" onClick={() => { navigate('/explore/items?filter=type%3A2'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText primary="Books" />
              </ListItemButton>
              <ListItemButton key="papers2" onClick={() => { navigate('/explore/items?filter=type%3A9'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <PaperIcon />
                </ListItemIcon>
                <ListItemText primary="Papers" />
              </ListItemButton>
              <ListItemButton key="tools2" onClick={() => { navigate('/explore/items?filter=type%3A7'); setDrawerOpen(false); }}>
                <ListItemIcon>
                  <ToolIcon />
                </ListItemIcon>
                <ListItemText primary="Tools" />
              </ListItemButton>
            </StyledList>
          </ListSection>
        </ListRoot>
      </DrawerBox>
    </StyledDrawer>
  );
}

ViewDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
};

export default ViewDrawer;
